import { useConfigureStore } from '@finn/auto-ui/components/ConfigurePage';
import {
  GenericVehicleDetails,
  useCalculatePrice,
  VehicleContext,
} from '@finn/ua-vehicle';
import { useContext } from 'react';

export function useMonthlySubscriptionCost(
  initVehicle?: GenericVehicleDetails
) {
  const { vehicle } = useContext(VehicleContext);
  const { term, kilometerPackage, downPayment, downPaymentAmount } =
    useConfigureStore();

  const priceParams = {
    term,
    kilometerPackage,
    downPaymentAmount,
    isDownPayment: downPayment,
    vehicle: vehicle || initVehicle,
  };

  const monthlyCost = useCalculatePrice(priceParams);

  const total = monthlyCost;

  return { monthlyCost, total };
}
