import { getEcommerceProduct, VehicleContext } from '@finn/ua-vehicle';
import { CURRENCIES, useCurrentLocale } from '@finn/ui-utils';
import { useContext } from 'react';

import { useMonthlySubscriptionCost } from '~/modules/products/details/components/PriceBreakdown/hooks/useMonthlySubscriptionCost';

export function useTrackingData() {
  const { vehicle } = useContext(VehicleContext);
  const { total } = useMonthlySubscriptionCost();
  const { isUSA } = useCurrentLocale();
  const currency = isUSA ? CURRENCIES.USD : CURRENCIES.EURO;

  return {
    ...getEcommerceProduct(vehicle),
    currency,
    price: total,
  };
}
