import { useIsAppReady } from '@finn/platform-modules';
import { CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';

const useIsE2ETest = () => {
  const isAppReady = useIsAppReady();
  if (isAppReady) {
    return Cookies.get(CookieKeys.E2E_TEST_IN_PROGRESS) === 'yes';
  }

  return false;
};

export default useIsE2ETest;
