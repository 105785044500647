import { Button } from '@finn/design-system/atoms/button';
import { ToggleItem } from '@finn/design-system/atoms/toggle';
import { Add } from '@finn/design-system/icons/add';
import { Remove } from '@finn/design-system/icons/remove';
import { ToggleModule } from '@finn/design-system/modules/ToggleModule';
import { FALLBACK_ADDITIONAL_MILEAGE_FEE } from '@finn/ua-constants';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { getForBusinessCookie } from '@finn/ua-vehicle';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ui-modules';
import {
  getFormattedNumber,
  getFormattedPrice,
  isMobileApp,
  twoDecimalPlaces,
} from '@finn/ui-utils';
import get from 'lodash/get';
import React, { useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { useTrackingData } from '~/components/ProductDetails/utils/useTrackingData';
import VehicleContext from '~/contexts/Vehicle';
import useFormattedNumber from '~/hooks/useFormattedNumber';
import { getDistancePrices } from '~/utils/pdp';

import { useConfigureStore } from '../ConfigurePageStore';

type Price = { distance: number; price: number; duration: number };

const DistanceOptions: React.FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const i18n = useIntl();
  const isForBusiness = getForBusinessCookie();
  const { vehicle } = useContext(VehicleContext);
  const { setKilometerPackage, kilometerPackage } = useConfigureStore();

  const [showAll, setShowAll] = useState(false);
  const isExpPCPReducedMileage =
    useIsABVariant(Features.ExpPCPReducedMileage) && !isMobileApp();

  const vehiclePrice = get(vehicle, 'downpayment_prices', {}) as Record<
    string,
    number
  >;

  const distancePrices: Price[] = getDistancePrices(
    vehiclePrice,
    isForBusiness
  );

  const extraUnitPrice = useFormattedNumber(
    twoDecimalPlaces(
      get(vehicle, 'price.extra_km_price', FALLBACK_ADDITIONAL_MILEAGE_FEE)
    )
  );

  const distanceUnit = i18n.formatMessage({ id: 'general.distanceUnit' });

  const title = i18n.formatMessage({
    id: 'pdp.mileagePerMonth',
  });

  const disclaimer = i18n.formatMessage(
    {
      id: `pdp.mileagePackageDisclaimer.b2c`,
    },
    { extraUnitPrice }
  );

  const disclaimerTooltip = i18n.formatMessage({
    id: 'pdp.mileagePackageDisclaimerTooltip',
  });

  const trackingData = useTrackingData();
  const trackDistanceClicked = useCallback(
    (distance: string) => {
      PDPTracking.distanceClicked({
        ...trackingData,
        distancepackage: distance,
      });
    },
    [trackingData]
  );

  const toggleShowAll = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.MORE_LESS_BUTTON_CLICKED, {
      location: 'pcp',
      action: showAll ? 'less' : 'more',
      name: 'distance options',
    });
    setShowAll(!showAll);
  }, [showAll]);

  const shouldShowReducedMileage = isExpPCPReducedMileage && !showAll;

  return (
    <div className="pb-6">
      <ToggleModule
        className="mt-1"
        cols={2}
        title={title}
        titleAs={'h6'}
        defaultValue={String(kilometerPackage)}
        value={String(kilometerPackage)}
        onValueChange={(value) => {
          trackDistanceClicked(title);
          if (value) {
            setKilometerPackage(Number(value));
          }
        }}
        subtitle={`${disclaimer} ${disclaimerTooltip}`}
      >
        {distancePrices
          .slice(0, shouldShowReducedMileage ? 4 : distancePrices.length)
          .map((option) => {
            const description =
              option.price > 0
                ? `+ ${getFormattedPrice(i18n, option.price, 0)}`
                : i18n.formatMessage({ id: 'pdp.free' });

            return (
              <ToggleItem
                key={option.distance}
                variant="regular"
                title={`${getFormattedNumber(
                  i18n,
                  option.distance
                )} ${distanceUnit}`}
                value={String(option.distance)}
                description={description}
              />
            );
          })}

        <div className="col-span-2">
          {isExpPCPReducedMileage && (
            <Button
              variant="ghost"
              size="md"
              className="h-10 w-full justify-start pl-2.5"
              onClick={toggleShowAll}
            >
              {showAll ? (
                <Remove className="mr-2.5 min-h-5 min-w-5" />
              ) : (
                <Add className="mr-2.5 min-h-5 min-w-5" />
              )}
              {i18n.formatMessage({ id: showAll ? 'plp.less' : 'plp.more' })}
            </Button>
          )}
        </div>
      </ToggleModule>
    </div>
  );
};

export default React.memo(DistanceOptions);
