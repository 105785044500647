import {
  ContractDuration,
  DistanceOptions,
} from '@finn/auto-ui/components/ConfigurePage';
import { useConfigureStore } from '@finn/auto-ui/components/ConfigurePage/ConfigurePageStore';
import { ContinueButton } from '@finn/auto-ui/components/ConfigurePage/ContinueButton';
import { DownPayment } from '@finn/auto-ui/components/ConfigurePage/DownPayment/DownPayment';
import { useExtendedAccount } from '@finn/auto-ui/contexts/ExtendedAccount';
import { Badge } from '@finn/design-system/atoms/badge';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from '@finn/design-system/atoms/drawer';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { Login, useLoginText } from '@finn/platform-modules';
import {
  DEFAULT_DISCOUNT_PERCENTAGE,
  DEFAULT_DOWN_PAYMENT,
} from '@finn/ua-constants';
import { usePCPLoginExp } from '@finn/ua-featureflags';
import {
  interactionTrackingEvent,
  TrackingEventName,
  useTrackingStore,
} from '@finn/ua-tracking';
import {
  calculateTotalPrice,
  GenericVehicleDetails,
  getForBusinessCookie,
  triggerEcommerceOrderingTrackingEvent,
  usePreDiscountRawValue,
  VehicleContext,
} from '@finn/ua-vehicle';
import { getFormattedPrice, getTermLabel, useSession } from '@finn/ui-utils';
import cn from 'classnames';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { usePartnerDiscount } from '~/hooks/usePartnerDiscount';
import { VehicleHeader } from '~/modules/products/details/components/common';
import { getCheckoutCartUrl } from '~/utils/checkout';

import { useMonthlySubscriptionCost } from '../PriceBreakdown/hooks/useMonthlySubscriptionCost';
import { PriceDisplay } from './PriceDisplay';

enum PCPStep {
  ConfigureStep = 1,
  LoginStep = 2,
  DownPaymentStep = 3,
}
const stepNameMap: Record<PCPStep, string> = {
  [PCPStep.ConfigureStep]: 'configure',
  [PCPStep.LoginStep]: 'login',
  [PCPStep.DownPaymentStep]: 'downpayment',
};

type ConfigureVehicleModalProps = {
  open: boolean;
  onClose: () => void;
  loadingEdit?: boolean;
  handleEditConfig?: () => void;
};

const ANIMATION_TIMEOUT = 300;

export const ConfigureVehicleModal: React.FC<ConfigureVehicleModalProps> = ({
  open,
  onClose,
  handleEditConfig,
  loadingEdit,
}) => {
  const intl = useIntl();
  const partnerDiscount = usePartnerDiscount();
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    if (partnerDiscount) {
      track(TrackingEventName.PCP_PARTNER_DISCOUNT_SHOWED, {
        additionalProps: { partnerDiscount },
      });
    }
  }, [partnerDiscount, track]);

  const { vehicle } = useContext(VehicleContext);

  const {
    term,
    onContinue,
    downPayment: isDownPayment,
    isLoading: isLoadingFromStore,
    kilometerPackage,
    monthlyPrice,
  } = useConfigureStore();

  const [continueDisabled, setContinueDisabled] = useState(false);

  const monthlyPriceWithoutDownpayment = calculateTotalPrice({
    vehicle,
    kilometerPackage,
    term,
    isDownPayment: false,
  });

  const [step, setStep] = useState({
    prevStep: null,
    currentStep: PCPStep.ConfigureStep,
  });
  const { prevStep, currentStep } = step;

  const isCheckoutEditModal = Boolean(handleEditConfig);

  const [session] = useSession();
  const { isEnabled: isExpPCPLogin, isVariantC: isExpPCPLoginCVariant } =
    usePCPLoginExp();
  const scrollContainer = useRef<HTMLDivElement>(null);

  const isForBusiness = getForBusinessCookie();

  const { total, monthlyCost } = useMonthlySubscriptionCost(vehicle);
  let totalWithDiscount = total;
  let preDiscountPrice = usePreDiscountRawValue({
    vehicle,
    price: monthlyCost,
    term,
    km: kilometerPackage,
    isForBusiness,
    isDownPayment,
  });

  if (partnerDiscount > 0) {
    if (!preDiscountPrice) {
      preDiscountPrice = total;
    }
    totalWithDiscount = total - total * partnerDiscount;
  }

  const originalPriceFormatted = getFormattedPrice(
    intl,
    preDiscountPrice || 0,
    0
  );
  const finalPriceFormatted = getFormattedPrice(intl, totalWithDiscount, 0);

  const subtitle = intl.formatMessage({
    id: `pdp.price.${isForBusiness ? 'b2b' : 'b2c'}_vat`,
  });

  const isShortPreDiscountPrice = preDiscountPrice?.toString().length === 4;

  const handleModalClose = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: 'configure',
      location: stepNameMap[currentStep],
    });
    onClose();
  }, [onClose]);

  const changeStep = useCallback(
    (newStep: PCPStep) => {
      setStep({
        prevStep: currentStep,
        currentStep: newStep,
      });
    },
    [currentStep]
  );

  const tempDisableContinue = useCallback(() => {
    setContinueDisabled(true);
    setTimeout(() => setContinueDisabled(false), ANIMATION_TIMEOUT);
  }, []);

  const handleContinue = useCallback(() => {
    scrollContainer.current?.scrollTo(0, 0);
    if (handleEditConfig) {
      handleEditConfig();

      return;
    }

    if (currentStep === PCPStep.ConfigureStep) {
      changeStep(PCPStep.DownPaymentStep);
      tempDisableContinue();

      return;
    }

    if (isExpPCPLogin && currentStep !== PCPStep.LoginStep && !session) {
      changeStep(PCPStep.LoginStep);
      tempDisableContinue();
      triggerEcommerceOrderingTrackingEvent({
        eventName: TrackingEventName.PRODUCT_ADDED,
        kmPackage: kilometerPackage,
        monthlyPrice,
        monthlyPriceWithoutDownpayment,
        downPaymentAmount: isDownPayment ? DEFAULT_DOWN_PAYMENT : undefined,
        term,
        vehicle,
      });

      return;
    }

    onContinue();
  }, [changeStep, handleEditConfig, isExpPCPLogin, onContinue]);

  const {
    createLead,
    setExtendedAccountInProgress,
    extendedAccountInProgress,
  } = useExtendedAccount();

  useEffect(() => {
    if (
      session &&
      !extendedAccountInProgress &&
      currentStep === PCPStep.LoginStep
    ) {
      onContinue();
    }
  }, [currentStep, extendedAccountInProgress, session]);

  const isMDScreen = useMediaQuery('md');

  const buildConfigureSection = useMemo(() => {
    return (
      <>
        <VehicleHeader vehicle={vehicle} />
        <ContractDuration />
        <DistanceOptions />
      </>
    );
  }, [vehicle, isCheckoutEditModal]);

  const buildDownPaymentSection = useMemo(() => {
    return (
      <>
        <VehicleHeader vehicle={vehicle} />
        <DownPayment />
      </>
    );
  }, [vehicle]);

  const buildLoginSection = useMemo(() => {
    if (!isExpPCPLogin) {
      return null;
    }

    let loginHeaderComponent = null;
    if (isExpPCPLoginCVariant) {
      loginHeaderComponent = (
        <LoginHeader
          isDownPayment={isDownPayment}
          vehicle={vehicle}
          term={term}
          kmPackage={kilometerPackage}
          formattedPrice={finalPriceFormatted}
        />
      );
    }

    const vehicleId = vehicle?.id ?? '';

    return (
      <Login
        isLoadingRestore={extendedAccountInProgress}
        loginHeaderComponent={loginHeaderComponent}
        vehiclePower={vehicle?.power || null}
        createLead={(formData) =>
          createLead(formData, {
            term,
            kilometerPackage,
            vehicleId,
            downpaymentSelected: isDownPayment,
          })
        }
        setExtendedAccountInProgress={setExtendedAccountInProgress}
        cartURL={getCheckoutCartUrl(
          vehicleId,
          term,
          kilometerPackage,
          isDownPayment ? DEFAULT_DOWN_PAYMENT : undefined
        )}
      />
    );
  }, [
    isExpPCPLogin,
    isExpPCPLoginCVariant,
    vehicle?.id,
    kilometerPackage,
    term,
    finalPriceFormatted,
    createLead,
    setExtendedAccountInProgress,
  ]);

  const buildFooterSection = useMemo(() => {
    const location =
      currentStep === PCPStep.ConfigureStep ? 'configure' : 'downpayment';

    return (
      <DrawerFooter className="z-10">
        <PriceDisplay
          subtitle={subtitle}
          finalPrice={finalPriceFormatted}
          originalPrice={originalPriceFormatted}
          isShortPrice={isShortPreDiscountPrice}
          hasOriginalPrice={Boolean(preDiscountPrice)}
        />

        <div className="w-1/2">
          <ContinueButton
            isInPDP
            testId="submit-config"
            location={location}
            onClick={handleContinue}
            isLoading={isLoadingFromStore || loadingEdit || continueDisabled}
          />
        </div>
      </DrawerFooter>
    );
  }, [
    currentStep,
    subtitle,
    finalPriceFormatted,
    originalPriceFormatted,
    isShortPreDiscountPrice,
    preDiscountPrice,
    handleContinue,
    isLoadingFromStore,
    loadingEdit,
    continueDisabled,
  ]);

  const backButton = useMemo(() => {
    if (currentStep === PCPStep.ConfigureStep) {
      return;
    }

    if (isExpPCPLogin && currentStep === PCPStep.LoginStep) {
      return () => {
        interactionTrackingEvent(TrackingEventName.BACK_BUTTON_CLICKED, {
          location: stepNameMap[currentStep],
        });

        changeStep(PCPStep.DownPaymentStep);
      };
    }

    return () => {
      interactionTrackingEvent(TrackingEventName.BACK_BUTTON_CLICKED, {
        location: stepNameMap[currentStep],
      });

      changeStep(PCPStep.ConfigureStep);
    };
  }, [currentStep, isExpPCPLogin]);

  const isNotLoginStep = currentStep !== PCPStep.LoginStep;

  return (
    <Drawer
      open={open}
      onClose={handleModalClose}
      handleOnly
      direction={isMDScreen ? 'bottom' : 'right'}
    >
      <DrawerContent
        title={intl.formatMessage({ id: 'pdp.configure.generalTitle' })}
        onBack={backButton}
        className="z-[1302] !select-text overflow-y-hidden [&>div]:h-full"
      >
        <div
          ref={scrollContainer}
          className={cn(
            'border-pearl absolute left-0 top-14 h-full w-full grow overflow-y-auto overflow-x-hidden border-0 border-t border-solid md:top-[72px]',
            currentStep === PCPStep.ConfigureStep &&
              'max-h-[calc(100%-10rem)] md:max-h-[calc(100%-11rem)]'
          )}
        >
          <div
            id="configure-step"
            className={cn(
              'absolute left-0 top-0 flex h-full w-full flex-col gap-8 bg-white px-4 py-6 transition-[transform,opacity] md:px-20',
              currentStep === PCPStep.ConfigureStep
                ? 'z-10 translate-x-0 opacity-100'
                : `z-0 -translate-x-28 opacity-0`
            )}
          >
            {buildConfigureSection}
          </div>

          <div
            id="downpayment-step"
            className={cn(
              'absolute left-0 top-0 flex h-full w-full flex-col gap-8 bg-white px-4 py-6 transition-[transform,opacity] md:px-20',
              currentStep === PCPStep.DownPaymentStep
                ? 'z-10 translate-x-0 opacity-100'
                : `-top-full z-0 ${prevStep > currentStep ? '-' : ''}translate-x-28 opacity-0`
            )}
          >
            {buildDownPaymentSection}
          </div>

          <div
            id="login-step"
            className={cn(
              'absolute left-0 top-0 mb-16 flex flex-col bg-white px-4 py-6 pb-[6rem] transition-[transform,opacity] md:px-20',
              currentStep === PCPStep.LoginStep
                ? 'z-10 translate-x-0 opacity-100'
                : '-top-full z-0 translate-x-28 opacity-0'
            )}
          >
            {buildLoginSection}
          </div>
        </div>
        {isNotLoginStep && buildFooterSection}
      </DrawerContent>
    </Drawer>
  );
};

type LoginHeaderProps = {
  vehicle: GenericVehicleDetails;
  term: number;
  kmPackage: number;
  formattedPrice: string;
  isDownPayment?: boolean;
};
export const LoginHeader = ({
  vehicle,
  term,
  kmPackage,
  formattedPrice,
  isDownPayment,
}: LoginHeaderProps) => {
  const intl = useIntl();
  const { subtitle } = useLoginText();

  const flexTermLabel = intl.formatMessage({ id: 'checkout.flexTermLabel' });
  const downPaymentDiscountLabel = intl.formatMessage(
    { id: 'checkout.downpayment.discountLabel' },
    { discountPercentage: DEFAULT_DISCOUNT_PERCENTAGE }
  );

  return (
    <div className="flex flex-col space-y-6">
      <VehicleHeader vehicle={vehicle} />
      <hr className="border-pearl w-full border-0 border-b border-solid" />
      <div className="grid grid-cols-2 gap-y-4">
        <span className="body-14-light">
          {intl.formatMessage({
            id: 'checkout.contractDuration',
          })}
        </span>
        <span className="body-14-semibold">
          {getTermLabel(term, flexTermLabel, intl)}
        </span>

        <span className="body-14-light">
          {intl.formatMessage({ id: 'checkout.kmIncluded' })}
        </span>
        <span className="body-14-semibold">
          {intl.formatMessage(
            { id: 'checkout.kmPackageForMonth' },
            { kmPackage }
          )}
        </span>

        <span className="body-14-light">
          {intl.formatMessage({
            id: 'checkout.monthlyTotalLabel',
          })}
        </span>
        <span className="body-14-semibold">
          {formattedPrice}{' '}
          {intl.formatMessage({ id: 'checkout.vatLabelWithVat' })}
          {isDownPayment && (
            <div className="pt-1">
              <Badge
                variant="monochromeBlue"
                withStroke
                className="body-12-medium h-[19px]"
              >
                {downPaymentDiscountLabel}
              </Badge>
            </div>
          )}
        </span>
      </div>
      <hr className="border-pearl w-full border-0 border-b border-solid" />
      <p className="body-16-light pb-2">{subtitle}</p>
    </div>
  );
};
