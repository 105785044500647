import { getClosedUserGroupId } from '@finn/ua-vehicle';
import {
  CountryCode,
  formatPhoneNumber,
  IFinnSession,
  Locale,
} from '@finn/ui-utils';
import { omit } from 'lodash';
import merge from 'lodash/merge';
import pick from 'lodash/pick';

import { PlaceData } from '~/modules/location/data';
import {
  getLocationName,
  getStateShort,
  getStreetName,
  getStreetNumber,
} from '~/modules/location/google-maps/client';
import { createLead, processAddress, updateContact } from '~/services/checkout';
import {
  CartDealInfo,
  CartInfo,
  ClientType,
  ContactHubspotInfo,
  ContactInfo,
  ContactUpdateBody,
  DealCreationBody,
  LeadCreationBody,
} from '~/types/checkout';
import { VehicleOfferType } from '~/types/vehicle';
import {
  addressFields,
  checkUsingContactInfoAsDeliveryAddress,
} from '~/utils/checkout';
import { getGermanDateFormat, getReversedDate } from '~/utils/time';

import { HubspotDealInfo } from '../../utils/routeHandler';
import { checkIfStateEnabled } from '../ContactForm/options';
import { initialValues } from './config';

// TODO theme our validation schemas generate wrong types or this whole code below
// is wrong, for now I just override the type, but probably we should do a proper fix
type ContactValuesLead = any;
type ContactValues = any;

export type PickedCartDealInfo = Pick<
  CartInfo,
  'vehicleId' | 'kilometerPackage' | 'term'
>;
type CartDealInfoKey = keyof PickedCartDealInfo;
const getCartDealInfo = (cartInfo: PickedCartDealInfo) => {
  const list: CartDealInfoKey[] = ['vehicleId', 'kilometerPackage', 'term'];

  return pick<CartDealInfo, CartDealInfoKey>(cartInfo, list);
};

const getDataFromPreviousStep = (
  isForBusiness: boolean,
  cartInfo: CartInfo,
  contactHubspotInfo: ContactHubspotInfo
) => {
  return {
    contact: {
      type: isForBusiness ? ClientType.BUSINESS : ClientType.PRIVATE,
      email: cartInfo.email || contactHubspotInfo.email,
    },
    preferences: {},
  };
};

const getDataFromContactInfo = (
  contactInfo: ContactInfo,
  isForBusiness: boolean = false
) => {
  const hasDeliveryAddress =
    !checkUsingContactInfoAsDeliveryAddress(contactInfo);

  const data = {
    contact: {
      ...pick(contactInfo, Object.keys(initialValues.contact)),
      ...getReversedDate(contactInfo.birthday as string, 'birthday'),
    },
    ...(hasDeliveryAddress && {
      deliveryAddress: contactInfo.deliveryAddress,
    }),
    b2bContact: pick(contactInfo, Object.keys(initialValues.b2bContact)),
    preferences: {
      emailSubscription: !!contactInfo.emailSubscription,
      sameDeliveryAddress:
        !hasDeliveryAddress || Boolean(contactInfo.hasDeliveryAddress),
    },
  };

  // phone entered with previously may have missing country code, this will fill it when missing
  data.contact.phone = formatPhoneNumber(
    contactInfo.phone,
    Locale.GERMAN_GERMANY
  );

  if (!data.contact.type) {
    data.contact.type = isForBusiness
      ? ClientType.BUSINESS
      : ClientType.PRIVATE;
  }

  return data;
};

export const extractLocationProperties = (locationDetails) => {
  const houseNumber = getStreetNumber(locationDetails);
  const streetName = getStreetName(locationDetails);
  const city = getLocationName(locationDetails) || undefined;
  const state = getStateShort(locationDetails);

  let street = undefined;
  if (houseNumber && streetName) {
    street = `${houseNumber} ${streetName}`;
  } else if (streetName) {
    street = streetName;
  }

  return { houseNumber, street, city, state };
};

const getDataFromLocationSetter = (
  locationZip: string,
  locationDetails: PlaceData
) => {
  if (!(locationZip && locationDetails)) return {};
  const state = getStateShort(locationDetails);
  const isStateEnabled = checkIfStateEnabled(state);
  // todo: also use the new field to check if zip is validated
  if (isStateEnabled) {
    const { city, street } = extractLocationProperties(locationDetails);

    return {
      contact: {
        zipcode: locationZip || undefined,
        state,
        city,
        street,
      },
    };
  }
};

type ContactInitialValues = {
  isForBusiness: boolean;
  contactInfo: ContactInfo;
  cartInfo: CartInfo;
  contactHubspotInfo: ContactHubspotInfo;
  region: CountryCode;
  locationZip?: string;
  locationDetails?: PlaceData;
  session?: IFinnSession;
};

export const getInitialValues = ({
  isForBusiness,
  contactInfo,
  cartInfo,
  contactHubspotInfo,
  locationZip,
  locationDetails,
  session,
}: ContactInitialValues) => {
  // TODO should be ContactValues, but to apply them we need to refactor
  // huge pat of validations
  const formValues: any = merge(
    initialValues,
    getDataFromPreviousStep(isForBusiness, cartInfo, contactHubspotInfo),
    getDataFromContactInfo(contactInfo, isForBusiness),
    getDataFromLocationSetter(locationZip, locationDetails)
  );

  // we can not use 0 as default value, because it will be treated as filled
  // and placeholder will not be shown
  if (formValues?.b2bContact?.fleetSize === 0) {
    formValues.b2bContact.fleetSize = undefined;
  }

  if (session) {
    formValues.contact.email = session?.user?.email;
  }

  return formValues;
};

export const submitLeadContact = async (
  values: any,
  cartInfo: PickedCartDealInfo,
  locale: string
) => {
  const contactData = {
    ...omit(values.contact, [
      'city',
      'housenumber',
      'state',
      'street',
      'zipcode',
      'type',
    ]),
    downpaymentSelected: values?.downpaymentSelected,
    cugId: getClosedUserGroupId(),
    phone: formatPhoneNumber(values.contact.phone, locale as Locale),
    ...(values.contact.birthday && {
      birthday: getGermanDateFormat(values.contact.birthday),
    }),
    ...getCartDealInfo(cartInfo),
  };

  return await createLead(contactData as LeadCreationBody, { locale });
};

export const submitUpdateContact = async (
  values: ContactValuesLead,
  cartInfo: CartInfo,
  hubspotDealInfo: HubspotDealInfo | {},
  locale: string
) => {
  const contactData = {
    ...omit(values.contact, [
      'city',
      'housenumber',
      'state',
      'street',
      'zipcode',
      'type',
    ]),
    phone: formatPhoneNumber(values.contact.phone, locale as Locale),
    ...(values.contact.birthday && {
      birthday: getGermanDateFormat(values.contact.birthday),
    }),
    ...hubspotDealInfo,
    ...getCartDealInfo(cartInfo),
  };

  return await updateContact(contactData as ContactUpdateBody, { locale });
};

export const submitContact = async (
  values: ContactValues,
  cartInfo: CartInfo,
  hubspotDealInfo: HubspotDealInfo | {},
  offerType: VehicleOfferType,
  locale: string,
  isDirectCheckoutEnabled = false
) => {
  const contactValues = {
    ...values.contact,
    deliveryAddress: values.preferences.sameDeliveryAddress
      ? pick(values.contact, addressFields)
      : values.deliveryAddress,

    phone: formatPhoneNumber(values.contact.phone, locale as Locale),
    ...(values.contact.birthday && {
      birthday: getGermanDateFormat(values.contact.birthday),
    }),
  };
  const isMiniB2B = values.contact.type === ClientType.BUSINESS;
  const b2bValues = isMiniB2B ? values.b2bContact : initialValues.b2bContact;
  const fleetSizeNum = parseInt(b2bValues.fleetSize, 10);

  const contactData = {
    ...contactValues,
    ...b2bValues,
    ...values.preferences,
    ...hubspotDealInfo,
    ...getCartDealInfo(cartInfo),
    fleetSize: isMiniB2B
      ? fleetSizeNum > 0
        ? fleetSizeNum
        : undefined
      : undefined,
    isDirectCheckoutEnabled,
  };

  return await processAddress(contactData as DealCreationBody, { locale });
};

export const shouldTriggerPositiveLead = ({
  region,
  creditScore,
  isCreditScoreValid,
}: {
  region: CountryCode;
  creditScore: {
    schufaScore: number | string;
    ficoScore: number | string;
  };
  isCreditScoreValid: boolean;
}): boolean => {
  // isCreditScoreValid is also true in case of failed check.
  // So, to trigger positive lead, we must check if credit score exists
  const hasCreditScore =
    (region === CountryCode.DE && creditScore?.schufaScore) ||
    (region === CountryCode.US && creditScore?.ficoScore);

  return Boolean(hasCreditScore && isCreditScoreValid);
};

export const mergeStreetWithExtra = (street = '', extra = '') => {
  if (!extra || !street) return street;
  if (street.toLowerCase().includes(`, ${extra.toLowerCase()}`)) return street;

  return `${street}, ${extra}`;
};
